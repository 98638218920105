:root {
  --maroon: #840050ff;
  --blue: #5da8dcff;
  --green: #80ba3cff;
  --turquoise: #07b6a1ff;
  --red: #d81d40ff;
  --cancel-grey: #e0e0e0;
  --hover-grey: #423f3f;
  --reports-grey: #423f3f;
  --reports-negative: #d81d40ff;
  --reports-positive: #80ba3cff;
  --reports-neutral: #ffad00ff;
  --previous-reports-grey: #746e6e;
  --previous-reports-negative: #e08892;
  --previous-reports-positive: #9caa77;
  --previous-reports-neutral: #f8cf87;
  --app-text-color-default: #423f3f;
  --app-text-color-white: #ffffff;
  --app-background-color-default: #423f3f;
  --app-background-color-white: #ffffff;
  --app-border-color-default: #423f3f;
  --app-checkbox-color-primary: #0175fe;
}

html {
  font-family: "Rubik", sans-serif !important;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #423f3f !important;
}

body {
  margin: 0 !important;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: var(--app-text-color-default) !important;

}

h1 {
  font-size: 2.5em;
}

table,
th {
  border: 0 !important;
}

thead {
  position: sticky;
  z-index: 1;
}

.page-heading {
  font-size: 2em;
  text-transform: uppercase;
  margin: 0 !important;
  color: var(--app-text-color-default) !important;
}

.container-view {
  margin: auto 0;
  padding: 3vh 5vw;
  background-color: var(--app-background-color-white) !important;
}

.actions-container {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.actions-container p {
  cursor: pointer;
  color: #5da8dc;
}

.actions-container p:hover {
  color: #85b7fa;
  text-decoration: underline;
}

.actions-container-delete {
  color: var(--red) !important;
  cursor: pointer;
}

.actions-container-delete:hover {
  color: #ee3f65 !important;
  text-decoration: underline;
}

.dropdown-menu {
  z-index: 999;
}

.custom-floatRight {
  float: right !important;
}

.form-buttons {
  margin-top: 2vh;
  display: flex;
  justify-content: flex-end;
}

.table td,
.table tr,
.table th {
  border: 1px solid #ccc;
  color: var(--app-text-color-default) !important;
  background-color: var(--app-background-color-white) !important;
}

.table td {
  border: 0 !important;
}

th,
td {
  text-align: left !important;
}

.table th:before {
  content: " ";
  border: 0.5px solid #ccc;
  width: 99.7%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.table th:after {
  content: " ";
  border: 0.5px solid #ccc;
  width: 99.7%;
  position: absolute;
  top: 0;
  left: 0;
}

.table td {
  padding: 15px;
}

.table-button-tray {
  padding: 15px 0px 20px 0px;
  display: flex;
  justify-content: flex-end;
}

.table-button {
  margin: 5px;
}

.button {
  margin: 5px !important;
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 0.8em !important;
}

.cancel-button {
  margin: 5px !important;
  text-transform: uppercase !important;
  font-size: 0.8em !important;
}

.button:hover {
  background-color: var(--hover-grey) !important;
}

.maroon {
  background-color: var(--maroon) !important;
}

.blue {
  background-color: var(--blue) !important;
}

.turquoise {
  background-color: var(--turquoise) !important;
}

.green {
  background-color: var(--green) !important;
}

.yellow {
  background-color: var(--reports-neutral) !important;
}

.red {
  background-color: var(--reports-negative) !important;
}

.cancel {
  background-color: var(--cancel-grey) !important;
}

.MuiGrid-grid-md-6,
.MuiGrid-grid-sm-6,
.MuiGrid-grid-lg-6,
.MuiGrid-grid-xs-6,
.MuiGrid-grid-md-12,
.MuiGrid-grid-sm-12,
.MuiGrid-grid-lg-12,
.MuiGrid-grid-xs-12,
.MuiGrid-grid-md-4,
.MuiGrid-grid-sm-4,
.MuiGrid-grid-lg-4,
.MuiGrid-grid-xs-4 {
  padding: 0 15px 0 0;
}

.MuiGrid-spacing-xs-3 {
  width: 100% !important;
}

.custom-marginTop30 {
  margin-top: 30px !important;
}

@media (min-width: 600px) {
  .MuiToolbar-regular {
    min-height: 64px;
  }
}

.table .Component-horizontalScrollContainer-12 {
  border: 1px solid transparent;
}

.MuiPopover-paper {
  max-height: 30vh !important;
  max-width: 50vw !important;
}

body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 200;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.modal-content {
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 7px;
  max-width: 600px;
  min-width: 300px;
  z-index: 300;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.page-heading-container {
  display: flex;
  flex-direction: row;
  padding: 15px 0px 20px 0px;
}

.Mui-checked {
  color: var(--blue) !important;
}

.MuiInputBase-root {
  color: var(--app-background-color-default) !important;
}

.text-center {
  text-align: left !important;
  display: flex;
  justify-content: flex-start;
  align-items: start;
}

.legend-lines {
  display: flex;
  justify-content: space-between;
  margin-left: 30% !important;
  width: 70%;
  position: absolute;
  bottom: 20px;
  height: 100%;
}

.legend-line {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 10%;
}

.legend-percentages {
  display: flex;
  justify-content: space-between;
  margin-left: 25% !important;
  width: 75%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
}

.legend-percentage {
  width: 10%;
  text-align: right;
  font-weight: bold;
  font-size: smaller;
}

.legend-row-lines {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 20px;
  top: 0;
  height: 55vh !important;
}

.legend-row-line {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 25%;
}

li.MuiButtonBase-root {
  width: 100%;
}