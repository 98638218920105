.hosizontalBarContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hosizontalBarItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hosizontalBarQuestionAndPercentages {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.benchmarkBarQuestionAndPercentages {
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.hosizontalBarPercentages {
  height: 50px;
  display: flex;
  margin-bottom: 2em;
  background-color: var(--reports-grey) !important;
  width: 70% !important;
  align-items: flex-start;
}

.hosizontalBarQuestion {
  width: 30% !important;
  font-weight: bold;
  text-align: right;
  padding: 0px 10px;
  font-size: 1.25em;
}

.responseSegment {
  display: flex !important;
  flex-direction: column-reverse;
}

.positiveResponses {
  height: 100%;
  background-color: var(--reports-positive);
}

.neutralResponses {
  display: inline-block;
  height: 100%;
  background-color: var(--reports-neutral);
}

.negativeResponses {
  display: inline-block;
  height: 100%;
  background-color: var(--reports-negative);
}

.one {
  background-color: var(--reports-positive);
}

.two {
  background-color: var(--reports-neutral);
}

.three {
  background-color: var(--reports-negative);
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 40%;
  /* margin-left: 20%; */

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

